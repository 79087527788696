import React, { ChangeEvent, createRef, useState } from 'react'
import axios from 'axios'

function App() {
  const [file, setFile] = useState(null as File | null)
  const [image, setImage] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('#ffffff')
  const [isDragging, setIsDragging] = useState(false)
  const [isResizing, setIsResizing] = useState(false)
  const fileRef = createRef<HTMLInputElement>()

  function selectFile(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault()
    if (fileRef.current === null) {
      return
    }
    fileRef.current.click()
  }

  function onDropFile(e: React.DragEvent<HTMLAnchorElement>) {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    setFileAndPreview(file)
    setIsDragging(false)
  }

  function setFileAndPreview(file: File) {
    setFile(file)
    const reader = new FileReader()
    reader.onload = function (e: ProgressEvent<FileReader>) {
      setImage(e.target?.result as string)
    }
    reader.readAsDataURL(file)
  }

  function onFileChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files === null) {
      setFile(null)
      setImage('')
    } else if (e.target.files.length > 0) {
      setFileAndPreview(e.target.files[0])
    } else {
      setFile(null)
      setImage('')
    }
  }

  async function resizeImage() {
    if (file === null) {
      return
    }

    setIsResizing(true)
    const params = new FormData()
    params.append('image', file)
    params.append('background_color', backgroundColor)

    const response = await axios
      .post('api.php', params, {
        responseType: 'blob',
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .catch((error) => {
        console.error(error)
        return null
      })

    setIsResizing(false)
    if (response === null) {
      return
    }

    const url = window.URL.createObjectURL(response.data)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', file.name.replace(/\.[^\.]+$/, '.zip'))
    document.body.appendChild(link)
    link.click()
  }

  function selectFileClass(): string {
    const classes = ['select-file']
    if (image !== '') {
      classes.push('with-file')
    }
    if (isDragging) {
      classes.push('dragging')
    }
    return classes.join(' ')
  }

  return (
    <div className="resizer-container">
      <div className="card">
        <div className="card-content">
          <h1 className="title">iOS Screenshot Resizer</h1>
          <div className="block has-text-centered">
            <div style={{ marginBottom: '4px' }}>
              Convert <strong>5.5inch</strong> screenshot
            </div>
            <i className="material-icons">south</i>
            <br />
            <strong>6.5inch</strong> and <strong>iPad</strong> size.
          </div>
          <input
            ref={fileRef}
            type="file"
            onChange={onFileChange}
            style={{ display: 'none' }}
          />
          <a
            className={selectFileClass()}
            href="#test"
            onClick={selectFile}
            onDrop={onDropFile}
            onDragOver={(e) => {
              e.preventDefault()
              setIsDragging(true)
            }}
            onDragLeave={() => setIsDragging(false)}
          >
            {image !== '' ? (
              <img
                src={image}
                alt="preview"
                style={{ maxWidth: '100%', maxHeight: '300px' }}
              />
            ) : (
              <div className="has-text-centered">
                Drag 5.5inch screenshot here
                <br />
                or click to select file.
              </div>
            )}
          </a>
          <div className="color-select">
            <div>Background Color</div>
            <input
              type="color"
              value={backgroundColor}
              onChange={(e) => setBackgroundColor(e.target.value)}
            />
          </div>
          <div className="has-text-centered">
            <button
              className={`button is-primary ${isResizing ? 'is-loading' : ''}`}
              type="button"
              onClick={() => resizeImage()}
              disabled={image === ''}
            >
              Resize
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
